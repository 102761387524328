@media (max-width: 991px) {
  .container {
    padding: 0;
    margin-bottom: 20px;
  }
}

@media (max-width: 2560px) {
  .container {
    margin-bottom: 70px;
  }
}
